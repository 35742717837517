<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div v-if=false
           class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{'show': isCalendarOverlaySidebarActive}"
      >
        <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{'show': false}"
        @click="isCalendarOverlaySidebarActive = false"
      />

    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'

import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'
import {codeSearch} from "@/views/extensions/tree/code";
import i18n from "@/libs/i18n";
import ability from "@/libs/acl/ability";
import DashboardService from "@/basic/dashboardService";
import UserService from "@/basic/userService";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import itLocale from "@fullcalendar/core/locales/it";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
  },
  mounted() {
    let calendarApi = this.$refs.refCalendar

    console.log(calendarApi)
    let company_id = store.state.userCompany.id;

    const dashboardService = new DashboardService(useJwt)
    dashboardService.get_events(company_id).then(response => {
      console.log("xxx response", response.data)
      console.log("xxx options", this.calendarOptions)
      this.calendarOptions.locale = itLocale

      if (response.data.status === "success") {
        console.log("response", response.data)
        this.calendarOptions.events = response.data.events
//        calendarApi.addEvent(response.data.events)
      } else {

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: i18n.t('Generic error'),
            icon: 'AlertIcon',
            variant: 'danger',
            text: i18n.t('There was an error retrieving the events, please retry'),
          },
        })
      }
    });

  },

  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    fetchEvents()


    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
